<template>
  <div class="dashboard">
    <va-card :title="$t('ajuizamento.adimplentes.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
        <va-radio-button
          v-model="filtro"
          option="todos"
          label="Todos"
        />

        <va-radio-button
          v-model="filtro"
          option="semAcordo"
          label="Apenas sem acordo"
        />

        <va-radio-button
          v-model="filtro"
          option="comAcordo"
          label="Apenas com acordo"
        />
        <div class="md3">
          <va-input
            class="ma-0 ml-3 md3"
            v-model="parcelasPagas"
            label="Quantidade de parcelas pagas >="
          />
        </div>
      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >

        <template slot="dataContrato" slot-scope="props">
          {{new Date(props.rowData.dataContrato).toLocaleString('pt-br').substr(0, 10)}}
        </template>
        <template slot="dataUltimaParcelaPaga" slot-scope="props">
          {{new Date(props.rowData.dataUltimaParcelaPaga).toLocaleString('pt-br').substr(0, 10)}}
        </template>

        <template slot="valorLiberado" slot-scope="props">
          {{props.rowData.valorLiberado.toLocaleString('pt-BR')}}
        </template>

        <template slot="taxa" slot-scope="props">
          {{props.rowData.taxa.toLocaleString('pt-BR')}}%
        </template>
      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import negativacoesService from '../../services/Cobranca/NegativacoesService'

export default {
  data () {
    return {
      filtro: 'todos',
      operacoes: [],
      perPage: 15,
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
      parcelasPagas: 0,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'id',
        title: 'EmprestimoId',
      }, {
        name: '__slot:dataContrato',
        title: 'Data',
      }, {
        name: 'razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: '__slot:valorLiberado',
        title: '$ Liberado',
      },
      {
        name: 'prazo',
        title: 'Prazo',
      },
      {
        name: '__slot:taxa',
        title: 'Taxa',
      },
      {
        name: 'parcelasPagasComAtraso',
        title: 'Parc. Pagas com Atraso',
      },
      {
        name: 'mediaDiasPagamentoFinal',
        title: 'Média Atraso Pgts em dias',
      },
      {
        name: 'diasPagamentoMaisAtrasadoFinal',
        title: 'Maior qtde dias atraso',
      },
      {
        name: 'parcelasPagas',
        title: 'Pagas',
      },
      {
        name: 'parcelasPagasAcordos',
        title: 'Pagas Acordo',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      let ret = null
      if (!this.term || this.term.length < 1) {
        ret = this.operacoes
      } else {
        ret = this.operacoes.filter(item => {
          return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
        })
      }

      if (this.filtro === 'semAcordo') {
        ret = ret.filter(x => x.acordoId == null && x.parcelasPagas >= this.parcelasPagas)
      } else if (this.filtro === 'comAcordo') {
        ret = ret.filter(x => x.acordoId != null && x.parcelasPagasAcordos >= this.parcelasPagas)
      } else {
        ret = ret.filter(x => x.parcelasPagasAcordos >= this.parcelasPagas || x.parcelasPagas >= this.parcelasPagas)
      }

      return ret
    },
  },
  methods: {
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await negativacoesService.adimplentes()).data.data
  },
}
</script>
